@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Mulish:wght@200;300;400;500;600;700;900&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&family=Poppins:wght@200;300;400;600&family=Roboto:ital,wght@0,400;0,700;1,300;1,400;1,700&family=Teko:wght@300;400;500;600;700&display=swap');
body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Plus Jakarta Sans';
}
body {
    background: url(images/bg.webp) center center;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    font-family: 'Plus Jakarta Sans';
}
:root{
    --buttom_color:#bf73f3;
    --button_hover_color:#cc8ff5;
    --focus_color:#a061cc;
    --primery_disable_btn:#e7cafa;
    --secondary_btn-color:#0f5238;
    --sec_hover_color:#0a3826;
    --sec_focus_color:#1d5d45;
    --error_color:#b00020;
    -o-border_color:#bbefe5;
}
.body_content {
    background: #f0f0f0;
}
.headerlogo {
    /* background:linear-gradient(to right, #84cec2 , #77c479); */
    padding:40px 50px;
    background-image:url(../src/images/bgheader.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    /* display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; */
     
}
.headerlogo img {
    max-width: 220px;
    margin: 0 auto;
    display: table;
    /* order: 2; */
    /* width: 30%; */
}
.margin-25 {
    padding: 25px 40px;
}
.margin-50 {
    padding: 50px 40px;
}
.headercontent {
    margin-top: 25px;
    /* order: 1;
    width: 70%; */
}
.headercontent h3 {
    color: #fbfbfb;
    margin-bottom: 0;
    text-align: center;
}
label {
    color: #292929;
    font-size: 16px;
    font-weight: 600;
}
p {
    color: #76787e;
    font-size: 16px;
    margin-bottom: 0;
}
.grad_btn,
.grad_btn:hover {
    /* background-image: linear-gradient(to right, #2a6481, #85c3c5); */
    border: none;
    padding: 12px 15px;
    color: #fff;
    display: table;
    margin-left: auto;
    background-color: var(--buttom_color);
}
button.trans_btn,
button.trans_btn:hover {
    background: 0 0;
    color: var(--secondary_btn-color);
    padding: 10px 0;
}
button.border_trans_btn {
    background: 0 0;
    border: 1.5px solid var(--secondary_btn-color);
    color: var(--secondary_btn-color);
    padding: 8px 15px;
    max-width: 153px;
    width: 154px;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 600;
}
.btn_icon {
    margin-left: 15px;
    margin-top: -5px;
    cursor: pointer;
}
.radio_select .btn_icon {
    margin-top: -15px;
}
.arrow_size {
    max-width: 15px;
    margin-left: 10px;
    margin-top: -4px;
}
.trans_btn img.arrow_size {
    margin-left: 0;
    margin-right: 7px;
}
.dot-icon {
    margin-left: 25px;
}
.dot-icon p:before {
    position: relative;
    content: "";
    width: 16px;
    height: 16px;
    background:#7bc589;
    display: block;
    top: 20px;
    left: -25px;
    border-radius: 50%;
}
.footer {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
input,
select,
textarea {
    width: 100%;
}
.oneeightyrotate {
    transform: rotate(180deg);
}
.radio {
    display: block;
    position: relative;
    padding-left: 5px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #76787e;
    font-weight: 400;
}
.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: var(--secondary_btn-color);
    border-radius: 50%;
}
.radio:hover input ~ .checkmark {
    background-color: var(--sec_hover_color);
}
.radio input:checked ~ .checkmark {
    background-color: transparent;
    border: 2px solid var(--secondary_btn-color);
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.radio input:checked ~ .checkmark:after {
    display: block;
}
.radio .checkmark:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--secondary_btn-color);
}
.message_text {
    height: 192px;
    color: #76787e;
    padding: 10px 15px;
    border-radius: 8px;
    overflow-x: auto;
}
.message_text ul {
    list-style: none;
    padding: 0;
}
.submit_text {
    text-align: center;
    max-width: 515px;
    margin: auto;
}
.custom_datepiker .field {
    width: 100%;
}
.custom_datepiker .ui.segment {
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
    margin: 1rem 0;
    padding: 1em;
    border-radius: 0.28571429rem;
    border: 1px solid rgba(34, 36, 38, 0.15);
}
.custom_datepiker .ui.icon.input input {
    background: #fff;
    border: none;
    font-size: 15px;
    border-radius: 0;
    padding: 8px 11px;
    width: 100%;
    border-radius: 5px;
}
.custom_datepiker .ui.segment {
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
    margin: 1rem 0;
    padding: 1em;
    border-radius: 0.28571429rem;
    border: 1px solid rgba(34, 36, 38, 0.15);
}
.custom_datepiker .ui.basic.button,
.custom_datepiker .ui.basic.buttons .button {
    background: 0 0;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    border-radius: 0.28571429rem;
    text-transform: none;
    text-shadow: none;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
}
.custom_datepiker .ui.compact.icon.button,
.custom_datepiker .ui.compact.icon.buttons .button {
    padding: 6px 2px;
    width: 24px;
}
.custom_datepiker .ui.button {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: #e0e1e2;
    color: rgba(0, 0, 0, 0.6);
    /* font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif; */
    margin: 0 0.25em 0 0;
    padding: 0.78571429em 1.5em;
    text-transform: none;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
    will-change: "";
    -webkit-tap-highlight-color: transparent;
}
.custom_datepiker .ui.icon.button > .icon,
.custom_datepiker .ui.icon.buttons .button > .icon {
    opacity: 0.9;
    margin: 0;
    vertical-align: top;
}
.custom_datepiker .ui.button > .icon:not(.button) {
    height: 0.85714286em;
    opacity: 0.8;
    margin: 0 0.42857143em 0 -0.21428571em;
    -webkit-transition: opacity 0.1s ease;
    transition: opacity 0.1s ease;
    vertical-align: "";
    color: "";
}
select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(images/droparrow.png);
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: center;
}
.custom_datepiker i.icon {
    display: inline-block;
    opacity: 1;
    margin: 0 0.25rem 0 0;
    width: 1.18em;
    height: 1em;
    /* font-family: Icons; */
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.custom_datepiker i.icon.angle.double.left:before {
    margin-right: 22px;
    content: "<<";
}
.custom_datepiker i.icon.angle.left:before {
    content: "<";
}
.custom_datepiker i.icon.angle.right:before {
    content: ">";
}
.custom_datepiker i.icon.angle.double.right:before {
    margin-right: 6px;
    content: ">>";
}
.custom_datepiker .clndr-control .left {
    padding-left: 5px;
}
.custom_datepiker .clndr-control .right {
    padding-left: 5px;
}
.custom_datepiker .clndr-cell-disabled {
    cursor: default;
    opacity: 0.45;
    background: var(--secondary_btn-color);
    color: #fff;
    opacity: 1;
    text-align: center;
}
.custom_datepiker .ui.fluid.button {
    display: block;
}
.custom_datepiker .ui.compact.button,
.custom_datepiker .ui.compact.buttons .button {
    width: 100%;
}
input#date::placeholder {
    text-transform: lowercase;
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: var(--secondary_btn-color)          ;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: var(--sec_hover_color) !important;
}
.ReactFlagsSelect-module_selectBtn__19wW7:after {
    border-top: 12px solid var(--secondary_btn-color);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 0;
}
.ui.icon.input input {
    text-transform: lowercase;
}
.ui.icon.input i.calendar.link.icon {
    display: none;
}
span.error.filerror {
    margin-top: 20px;
    display: table;
}
span.msg-show {
    color: var(--secondary_btn-color);
    position: relative;
    bottom: 22px;
    font-size: 13px;
    font-weight: 600;
}
span.msg-shown {
    color: red;
    position: relative;
    bottom: 22px;
    font-size: 13px;
    font-weight: 600;
}
button.border_trans_btn.disable {
    pointer-events: none;
}
.swal2-styled.swal2-confirm {
    width: auto;
}
.img-hover-zoom {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: relative;
    margin-right: 20px;
}
.d-flex.justify-content-center.align-items-centers {
    position: relative;
    top: 5px;
}
p.text-white.mb-0:before {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    opacity: 0.4;
    background-color: #000;
}
p.text-white.mb-0.text {
    bottom: 25px;
}
p.text-white.mb-0 {
    position: absolute;
    bottom: 48px;
    right: -14px;
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 100%;
}
.img-hover-zoom:hover img.btn_icon {
    transform: scale(1.1);
    box-shadow: 2px 2px 2px #4b4b4b;
    opacity: 1;
    filter: grayscale(0);
}
.text-bg {
    position: relative;
}
.flex-th.cons div {
    background: url(images/Conservative\ Fund.webp);
    background-size: cover;
    background-repeat: no-repeat;
}
.flex-th.First div {
    background: url(images/First\ Home\ Buyer\ Strategy.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.flex-th.Balance div {
    background: url(images/Balanced\ Strategy.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.flex-th.Growth div {
    background: url(images/Growth\ Fund.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
}
.flex-th.ClimateGrowth div {
    background: url(images/Climatebaby.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 24%;
}
.flex-th.LegacyPath div {
    background: url(images/Retirement\ Plus\ Strategy.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
}
.flex-th.LegacyPath1 div {
    background: url(images/Liquidity\ Fund.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
}
.flex-th div {
    width: 180px;
    height: 180px;
    background-size: cover;
    margin: 0;
    text-align: center;
    border-radius: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    background: url(images/fund.jpg);
    background-size: 100%;
    background: #fff;
    position: relative;
}
.flex-th {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
    align-items: center;
}
.flex-th {
    margin: auto;
}
.flex-th p {
    color: #fff;
    padding: 10px 23px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 13px;
    position: relative;
    z-index: 9;
}
.flex-th div:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    background: #0006;
}
.flex-th div span {
    height: 92px;
    display: table;
    width: 100%;
    opacity: 0.4;
}
.flex-th div:before {
    background: #fff;
    height: 100%;
    position: absolute;
    content: "";
    width: 100%;
    opacity: 0.3;
}
.flex-th div:hover:before {
    opacity: 0;
}
.flex-th div:hover {
    transition: 0.3s;
    opacity: 1;
}
.margin-24 {
    padding: 21px 26px;
}
.btn_icon {
    margin-left: 15px;
    margin-top: -5px;
    cursor: pointer;
}
.current {
    border: 6px solid var(--secondary_btn-color);
    transition: 0.3s;
    transform: scale(1.1);
    background: 0 0;
}
.current span,
.current:before {
    background: 0 0;
}
/* .headerlogo.side {
    padding: 20px 20px 60px;
} */
.pass-wrapper {
    position: relative;
    display: flex;
    margin-bottom: 14px;
}
.eye-password {
    position: absolute;
    top: 34px;
    right: 8%;
    font-size: 20px;
}
i:hover {
    color: #000;
    cursor: pointer;
}
.fa-eye:before {
    content: "\f06e";
    top: 5px;
    position: relative;
    color: var(--secondary_btn-color);
}
.irdNumbers {
    color: var(--error_color);
}
.custom_datepiker button.ui.compact.fluid.disabled.button.clndr-button-today {
    display: none;
}
@media only screen and (max-width: 768px) {
    .margin-25 {
        padding: 25px 0;
    }
    .headerlogo{

        padding: 25px 15px;
    }
    .form .react-tel-input .form-control {
        width: 100%;
    }
    /* .headercontent {
        order: 2;
        width: 100%;
    }
    .headerlogo {
        justify-content: center;
    }
    .headerlogo img {
        order: 1;
        width: auto;
    }
    .headercontent h3{
        text-align: center;
    } */
   
     
}
.loaderemail {
    border: 3px solid #ccc;
    border-radius: 50%;
    border-top: 3px solid var(--secondary_btn-color);
    width: 14px;
    height: 14px;
    animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
.mainloader_div {
    display: flex;
    align-items: center;
}
i.far.fa-check-circle.color {
    color: var(--secondary_btn-color);
}
div#swal2-content a, p.trust a{
    color: var(--secondary_btn-color);
}

.clndr-cell:hover{
    color: #ffffff !important;
    background-color: var(--sec_hover_color) !important;
}
.clndr-cell-selected{
    background-color: var(--secondary_btn-color) !important;
    color: #ffffff !important;
}
 